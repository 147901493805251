import React, { Component } from "react";
import "../style/home.scss";
import Loading from "./common/Loading";
import AdIcon from "../icon/AdIcon";
import PresentIcon from "../icon/PresentIcon";
import NumberIcon from "../icon/NumberIcon";
import CountPrice from "../icon/CountPrice";
import Select from "react-select";
// import { DatePicker, Space } from "antd";
import moment from "moment";
// import 'antd/dist/antd.css';
import cookie from "react-cookies";
import * as GetSiteListAPI from "../apis/GetSiteListAPI";
import * as GetUserListAPI from "../apis/GetUserListAPI";
import * as GetChartAPI from "../apis/GetChartAPI";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";
import * as BangDanAPI from "../apis/BangDanAPI";
import BangDan from "../icon/BangDan";
import JiangBei from "../icon/JiangBei";
import { number } from "echarts";
import Pixel from "../icon/Pixel";
import * as GetPixelAPI from "../apis/GetPixelAPI";
import * as GetAdListAPI from "../apis/GetAdListAPI";
import NoContent from "../icon/NoContent";
import { DatePicker } from "@douyinfe/semi-ui";

// const { RangePicker } = DatePicker;
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      dates: null,
      starttime: "",
      endtime: "",
      userid: 0,
      userList: [],
      siteList: [],
      starttime: "",
      endtime: "",
      site: {
        value: "",
        label: "Please choose site ...",
      },
      user: { value: "", label: "Please choose user ..." },
      datatype: {
        value: "d",
        label: "天查询",
      },
      dates: [moment().subtract(1, "months").toDate(), moment().toDate()], // 转换成Date对象
      currentYear: moment().year(), // 当前年份
      dateType: [
        {
          value: "d",
          label: "天查询",
        },
        {
          value: "u",
          label: "周查询",
        },
        {
          value: "m",
          label: "月查询",
        },
      ],
      option: {
        grid: {
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["花费", "总出单数量", "总出单金额", "花费/总出单金额"],
          // right: "2%",
          top: "5%",
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [],
            name: "花费",
            type: "line",
            itemStyle: { color: "#59bcff" },
            smooth: true,
            stack: "Total",
            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
          },
          {
            data: [],
            name: "总出单数量",
            type: "line",
            stack: "Total",
            itemStyle: { color: "#4ad994" },
            smooth: true,
            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
          },
          {
            data: [],
            name: "总出单金额",
            type: "line",
            itemStyle: { color: "#fdb765" },
            smooth: true,
            stack: "Total",
            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
          },
          {
            data: [],
            name: "花费/总出单金额",
            type: "line",
            itemStyle: { color: "#ff6d59" },
            smooth: true,
            stack: "Total",
            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
          },
        ],
      },
      cost: "",
      orderNum: "",
      orderPrice: "",
      persent: "",
      usertype: 2,
      orderNumList: [],
      orderPriceList: [],
      persentList: [],
      pixelList: [],
      adList: [],
      ad: { value: "", label: "Please choose ad ..." },
      bangdanList: [],
    };
    this.getUserList = this.getUserList.bind(this);
    this.getSiteList = this.getSiteList.bind(this);
    this.handleRangeChange = this.handleRangeChange.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.getBangDan = this.getBangDan.bind(this);
    this.getPixelList = this.getPixelList.bind(this);
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }
  componentDidMount() {
    let param = cookie.load("token");
    let userid = 0;
    if (param) {
      console.log(param);
      if (param.user.type !== 2) {
        userid = param.user.id;
        this.setState({ userid, usertype: param.user.type });
      }
    }
    this.getUserList(userid);
    this.getSiteList(userid);
    this.getAdList(userid);
    let { dates } = this.state;
    let starttime, endtime;
    if (dates && dates.length === 2) {
      starttime = parseInt(dates[0].valueOf() / 1000); // 获取起始日期的时间戳（毫秒）
      endtime = parseInt(moment(dates[1]).endOf("day").valueOf() / 1000); // 获取结束日期的时间戳（毫秒）
      console.log(starttime, endtime);
    }
    this.setState({ starttime, endtime }, () => {
      this.getChart("d", userid, starttime, endtime, "");
      this.getBangDan(starttime, endtime);
      this.getPixelList(starttime, endtime, userid);
    });
  }

  getUserList(userid) {
    let userList = [];
    let user = {};
    GetUserListAPI.getUserListNoPage(userid).then((res) => {
      var data = res.data.data;
      if (data) {
        userList = data.list.map((item) => {
          return (item = {
            value: item.id,
            label: item.xingming,
          });
        });
        this.setState({ userList: userList });
      }
    });
  }

  getSiteList(userid) {
    let siteList = [];
    GetSiteListAPI.getSiteListNoPage(userid).then((res) => {
      var data = res.data.data;
      if (data) {
        siteList = data.list.map((item) => {
          return (item = {
            value: item.id,
            label: item.url,
          });
        });
        this.setState({ siteList: siteList });
      }
    });
  }

  getPixelList(starttime, endtime, userid, ad, url) {
    GetPixelAPI.getPixel(starttime, endtime, userid, ad, url).then((res) => {
      console.log(res);
      var data = res.data.data;
      if (data.length > 0) {
        let Fenmu = data[0].quantity;
        data = data.map((item, index) => {
          return {
            title: item.eventname,
            num: item.quantity,
            width: `${100 - index * 1.35}%`,
            persent: `${((item.quantity / Fenmu) * 100).toFixed(2)} %`,
          };
        });
        this.setState({ pixelList: data });
      }
    });
  }

  getChart(datatype, userid, starttime, endtime, url) {
    this.toggleShow();
    GetChartAPI.getChart(datatype, userid, starttime, endtime, url)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        if (code === 1) {
          var list = res.data.data.data;
          var data = res.data.data;
          this.setState({
            cost: data.payexpenses,
            orderNum: data.shuliang,
            orderPrice: data.amount,
            persent: data.ratio,
          });
          let { option } = this.state;
          let xData1 = [];
          let yData1 = [];
          let yData2 = [];
          let yData3 = [];
          let yData4 = [];
          list.map((item) => {
            xData1.push(item.time_format);
            yData1.push(item.payexpenses);
            yData2.push(item.quantity);
            yData3.push(item.money);
            yData4.push(item.ratio);
          });
          this.setState(
            (prevState) => {
              const newOption = { ...prevState.option }; // 复制整个option对象
              // 更新X轴数据
              newOption.xAxis.data = [...xData1]; // 用展开操作符来创建新的数组
              // 确保更新正确的系列数据，假设您的图表有一个系列
              newOption.series = [
                {
                  ...prevState.option.series[0], // 复制原有的系列配置
                  data: [...yData1], // 更新数据
                },
                {
                  ...prevState.option.series[1], // 复制原有的系列配置
                  data: [...yData2], // 更新数据
                },
                {
                  ...prevState.option.series[2], // 复制原有的系列配置
                  data: [...yData3], // 更新数据
                },
                {
                  ...prevState.option.series[3], // 复制原有的系列配置
                  data: [...yData4], // 更新数据
                },
              ];
              return { option: newOption };
            },
            () => {
              this.setState({ loadingShow: false });
              // 这个回调确保状态已经更新后执行，可以在这里进行后续操作，比如重新加载图表等
            }
          );
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  getBangDan(starttime, endtime) {
    BangDanAPI.bangDan(starttime, endtime).then((res) => {
      var code = res.data.code;
      var data = res.data.data;
      if (code === 1) {
        this.setState({
          bangdanList: data,
        });
      }
    });
  }

  getAdList(userid, url) {
    let adList = [];
    GetAdListAPI.getAdList(userid, url).then((res) => {
      var data = res.data.data;
      if (data) {
        adList = data.map((item) => {
          return (item = {
            value: item.ad,
            label: item.ad,
          });
        });
        this.setState({ adList: adList });
      }
    });
  }

  // 筛选时间
  handleRangeChange(dates) {
    if (dates && dates.length === 2) {
      const starttime = Math.floor(dates[0].valueOf() / 1000); // 获取开始时间戳（秒）
      const endtime = Math.floor(
        moment(dates[1]).endOf("day").valueOf() / 1000
      ); // 获取结束时间戳（秒）

      console.log(starttime, endtime);

      // 设置开始时间和结束时间
      this.setState({ dates, starttime, endtime }, () => {
        // 调用其他方法获取图表数据和排行榜数据
        this.getChart(
          this.state.datatype.value,
          this.state.userid,
          starttime,
          endtime,
          this.state.site.value === "" ? "" : this.state.site.label
        );
        this.getBangDan(starttime, endtime);
        this.getPixelList(starttime, endtime);
      });
    } else {
      // 如果没有选择日期范围，清空相关的状态
      this.setState({ starttime: null, endtime: null });
    }
  }

  selectChange(e, type) {
    switch (type) {
      case "type":
        this.setState({ datatype: e }, () => {
          this.getChart(
            e.value,
            this.state.userid,
            this.state.starttime,
            this.state.endtime,
            this.state.site.value === "" ? "" : this.state.site.label
          );
        });
        break;
      case "user":
        this.setState({ user: e, userid: e.value }, () => {
          this.getSiteList(e.value);
          this.setState({
            site: { value: "", label: "Please choose site ..." },
          });
          this.getChart(
            this.state.datatype.value,
            e.value,
            this.state.starttime,
            this.state.endtime,
            this.state.site.value === "" ? "" : this.state.site.label
          );
        });
        break;
      case "site":
        this.setState({ site: e }, () => {
          this.getChart(
            this.state.datatype.value,
            this.state.user.value,
            this.state.starttime,
            this.state.endtime,
            this.state.site.value === "" ? "" : this.state.site.label
          );
          this.getAdList(this.state.user.value, this.state.site.label);
          this.setState({ ad: { value: "", label: "Please choose ad ..." } });
          this.getPixelList(
            this.state.starttime,
            this.state.endtime,
            this.state.userid,
            "",
            e.label
          );
        });
        break;
      case "ad":
        this.setState({ ad: e }, () => {
          this.getPixelList(
            this.state.starttime,
            this.state.endtime,
            this.state.user.value,
            this.state.ad.value === "" ? "" : this.state.ad.label
          );
        });
        break;
      default:
        break;
    }
  }

  render() {
    const {
      dates,
      userList,
      siteList,
      datatype,
      dateType,
      site,
      user,
      userid,
      cost,
      orderNum,
      orderPrice,
      persent,
      usertype,
      orderNumList,
      orderPriceList,
      persentList,
      pixelList,
      adList,
      ad,
      bangdanList,
    } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="home-page">
          <div className="card-row">
            <div className="card">
              <div className="num">¥{cost}</div>
              <div className="title">总花费</div>
              <AdIcon size={50} color="#fff" className="icon" />
            </div>
            <div className="card">
              <div className="num">{orderNum}</div>
              <div className="title">出单总数量</div>
              <NumberIcon size={50} color="#fff" className="icon" />
            </div>
            <div className="card">
              <div className="num">¥{orderPrice}</div>
              <div className="title">出单总金额</div>
              <CountPrice size={50} color="#fff" className="icon" />
            </div>
            <div className="card">
              <div className="num">{persent}</div>
              <div className="title">总花费 / 出单总金额</div>
              <PresentIcon size={50} color="#fff" className="icon" />
            </div>
          </div>
          <div className="select-row">
            <DatePicker
              onChange={this.handleRangeChange}
              className="datePicker"
              type="dateRange"
              value={dates}
              density="compact"
            />
            <Select
              options={dateType}
              value={datatype || ""}
              className="type"
              onChange={(e) => this.selectChange(e, "type")}
            />
            {usertype === 2 ? (
              <Select
                className="user-select"
                placeholder="Please choose user..."
                options={userList}
                value={user || ""}
                onChange={(e) => this.selectChange(e, "user")}
              />
            ) : (
              ""
            )}

            <Select
              className="site-select"
              placeholder="Please choose site..."
              options={siteList}
              value={site || ""}
              onChange={(e) => this.selectChange(e, "site")}
            />
            <Select
              className="ad-select"
              placeholder="Please choose site..."
              options={adList}
              value={ad || ""}
              onChange={(e) => this.selectChange(e, "ad")}
            />
          </div>
          <div className="chart-row">
            {/* 榜单 */}
            <div className="bang-modle">
              <div className="title-head">
                <BangDan size={40} color="#dbbe19" />
              </div>
              <div className="title">
                <p>总榜单</p>
                <div className="right">
                  <CountPrice size={30} color="#fdb765" className="bang-icon" />
                  <NumberIcon size={30} color="#4ad994" className="bang-icon" />
                  <AdIcon size={30} color="#59bcff" className="bang-icon" />
                  <PresentIcon
                    size={30}
                    color="#ff6d59"
                    className="bang-icon"
                  />
                </div>
              </div>
              <div className="bang-content">
                <div className="li">
                  {bangdanList.map((item, index) => {
                    return (
                      <div className="bi" key={index}>
                        <div className="name">
                          {index === 0 && (
                            <JiangBei
                              size={20}
                              className="jiang"
                              color="#ff0808"
                            />
                          )}
                          {index === 1 && (
                            <JiangBei
                              size={20}
                              className="jiang"
                              color="#c5c216"
                            />
                          )}
                          {index === 2 && (
                            <JiangBei
                              size={20}
                              className="jiang"
                              color="#70d138"
                            />
                          )}
                          {index > 2 && (
                            <JiangBei
                              size={20}
                              className="jiang"
                              color="#424242"
                            />
                          )}
                          {item.xingming}
                        </div>
                        <div className="value">
                          <div className="je">¥{item.je}</div>
                          <div className="sl">{item.sl}</div>
                          <div className="ggf">¥{item.ggf || 0.0}</div>
                          <div className="bl">{item.bl}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* 像素事件 */}
            <div className="pixel-model">
              <div className="title-row">
                <Pixel size={45} />
              </div>
              <div className="model-box">
                {pixelList.length > 0 ? (
                  <React.Fragment>
                    {pixelList.map((item, index) => {
                      return (
                        <div
                          className="model-row"
                          key={index}
                          style={{ width: item.width }}
                        >
                          <div className="title">
                            {item.title} <span>{item.persent}</span>
                          </div>
                          <div className="num">{item.num} </div>
                        </div>
                      );
                    })}
                  </React.Fragment>
                ) : (
                  <NoContent size={200} />
                )}
              </div>
            </div>
            {/* 折线图 */}
            <div className="chart-modle">
              <ReactEcharts
                // option={option}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                echarts={echarts}
                // 强制刷新
                key={JSON.stringify(this.state.option)}
                option={this.state.option}
                theme={"theme_name"}
                className="chart"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
